import React from 'react'
import { Link, graphql } from 'gatsby'
import Seo from '../components/seo'
import NavMobile from '../components/nav_mobile'
import BackgroundImage from 'gatsby-background-image'
import Copyright from '../components/copyright'

export default ({ data }) => {
    const entry = data.markdownRemark
    return (
        <div className="page">
            <Seo title={entry.frontmatter.page_title} description={entry.frontmatter.meta_description} image="" pathname={entry.fields.slug} />
            <NavMobile />
            <div className="layout">
                <BackgroundImage fluid={entry.frontmatter.image.childImageSharp.fluid} backgroundColor={`#ffffff`}>
                    <div className="title__gradient">
                        <div className="layout__container">
                            <div className="layout__wide title center">
                                <h1>{entry.frontmatter.heading}</h1>
                            </div>
                        </div>
                    </div>
                </BackgroundImage>
            </div>
            <div className="layout grey">
                <div className="layout__container">
                    <div className="layout__wide">
                        <nav className="breadcrumb">
                            <p><Link to='/'>Home</Link> &nbsp;&rsaquo;&nbsp; <strong>Blog</strong></p>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="layout body">
                <div className="layout__container">
                    <div className="layout__wide text">
                        <div className="flex flex__space-around text">
                            <div className="flex__60">
                                {data.allMarkdownRemark.edges.map(({ node }) => {
                                    return (
                                        <article className="blog__list" key={node.id}>
                                            <h3><Link to={node.fields.slug}>{node.frontmatter.heading} &rsaquo;</Link></h3>
                                            <p>{node.excerpt}</p>
                                        </article>
                                    )
                                })}   
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Copyright />
        </div>
    )
}

export const query = graphql`
    query($slug: String!) {
        allMarkdownRemark(filter: {frontmatter: {template: {eq: "blog_entry"}}}) {
            edges {
                node {
                    id
                    frontmatter {
                        date(formatString: "DD MMMM, YYYY")
                        heading
                    }
                    fields {
                        slug
                    }
                    excerpt
                }
            }
        }
        markdownRemark( fields: { slug: { eq: $slug } }) {
            frontmatter {
            title
            page_title
            meta_description
            heading
            image {
                childImageSharp {
                    fluid(maxWidth: 1280) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
        fields {
            slug
        }
        html
        }
    }
`